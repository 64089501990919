<template>
  <!--begin::VehicleModels index-->
  <div>
    <div class="manager-title">
      <inline-svg
        class="manager-title-icon"
        src="/media/icons/car-primary.svg"
      />
      <div>
        {{ $t("VEHICLE_MODELS.TITLE_INDEX") }}
        <hr class="manager-title-sub" />
      </div>
    </div>

    <!--begin::Row Filters-->
    <div class="white-box mb-9">
      <div class="row pt-6 pb-3 align-items-top">
        <div class="col-md-6 d-flex d-row align-items-top">
          <h5 class="mr-3 mt-3 font-weight-bolder" style="white-space: pre">
            {{ $t("VEHICLE_MODELS.SEARCH") }}
          </h5>
          <v-text-field
            class="form-control search-text"
            v-model.lazy="SearchText"
            :placeholder="$t('GENERIC.SEARCH') + '...'"
            @keyup="onSearchTextKeyup"
          >
            <template v-slot:prepend-inner>
              <inline-svg src="/media/icons/search-alt-1-primary.svg" />
            </template>
          </v-text-field>
        </div>

        <div class="col-md-3 pt-3">
          <span
            class="action-icon"
            :class="[ShowFilters ? 'bg-primary' : '']"
            @click="() => (ShowFilters = !ShowFilters)"
          >
            <inline-svg
              v-if="ShowFilters"
              src="/media/icons/filter-alt-1.svg"
            />
            <inline-svg v-else src="/media/icons/filter-alt-1-primary.svg" />
          </span>
        </div>

        <div class="col-md-3 text-right p-0">
          <button
            class="btn button-filter btn-pill btn-primary mb-3"
            @click="$router.push('/manager/vehicles/new')"
          >
            <inline-svg src="/media/icons/add.svg" class="mr-3" />
            {{ $t("VEHICLE_MODELS.TITLE_ADD") }}
          </button>
          <br />
          <button
            class="btn button-filter btn-pill btn-tertiary"
            @click="$router.push('/manager/vehicles/types')"
          >
            <inline-svg src="/media/icons/edit-alt-2.svg" class="mr-3" />
            {{ $t("VEHICLE_MODELS.TYPES") }}
          </button>
        </div>
      </div>

      <div v-if="ShowFilters" class="row mt-5 py-6 box-filters">
        <div class="col-lg-3">
          <label>
            {{ $t("VEHICLE_MODELS.TYPE") }}
          </label>
          <select
            class="form-control select-down"
            v-model="FilterByVehicleTypeID"
            @change="searchVehicleModels(false)"
          >
            <option
              v-for="vt in VehicleTypeOptions"
              :key="vt.VehicleTypeID"
              :value="vt.VehicleTypeID"
              class="text-primary"
            >
              {{ getVehicleTypeName(vt.VehicleTypeID) }}
            </option>
          </select>
        </div>

        <div class="col-lg-3">
          <label>
            {{ $t("VEHICLE_MODELS.BRAND") }}
          </label>
          <v-text-field
            class="form-control"
            v-model.lazy="FilterByBrand"
            @keyup="onSearchTextKeyup"
          />
        </div>

        <div class="col-lg-3">
          <label>
            {{ $t("VEHICLE_MODELS.MODEL") }}
          </label>
          <v-text-field
            class="form-control"
            v-model.lazy="FilterByModel"
            @keyup="onSearchTextKeyup"
          />
        </div>

        <div class="col-lg-3 pt-7 pl-9">
          <button
            class="btn btn-pill btn-white button-form button-form-primary"
            @click="cleanFilters"
          >
            <inline-svg src="/media/icons/cross-primary.svg" />
            {{ $t("GENERIC.CLEAN_FILTERS") }}
          </button>
        </div>
      </div>
    </div>
    <!--end::Row Filters-->

    <!--begin::Row Searching-->
    <div v-if="ItemsSearching" class="search-info">
      <inline-svg class="mx-3" src="/media/icons/help-alt-5-success.svg" />
      {{ $t("GENERIC.SEARCHING") }}...
    </div>
    <!--end::Row Searching-->

    <!--begin::Table Results-->
    <v-data-table
      v-if="ItemsList.length > 0"
      class="white-table shadow-box"
      :headers="TableHeaders"
      :items="ItemsList"
      :items-per-page="ItemsPerPage"
      :options.sync="options"
      :server-items-length="ItemsTotal"
      @update:sort-by="updateSortBy"
      @update:sort-desc="updateSortDesc"
      hide-default-footer
    >
      <template v-slot:item="{ item }">
        <tr>
          <td>
            {{ item.Brand }}
          </td>
          <td>
            {{ item.Model }}
          </td>
          <td>
            {{ item.Version }}
          </td>
          <td>
            {{ item.YearFrom + " - " + item.YearTo }}
          </td>
          <td>
            {{ getVehicleTypeName(item.VehicleTypeID) }}
          </td>
          <td>
            <span
              class="action-icon"
              @click="
                $router.push('/manager/vehicles/edit/' + item.VehicleModelID)
              "
            >
              <inline-svg
                :src="
                  '/media/icons/' +
                    (item.ImportedFromCar2DB ? 'view' : 'edit-alt-2') +
                    '-primary.svg'
                "
              />
            </span>

            <span
              v-if="!item.ImportedFromCar2DB"
              class="action-icon ml-4"
              @click="deleteVehicle(item)"
            >
              <inline-svg src="/media/icons/delete-alt-2-primary.svg" />
            </span>
          </td>
        </tr>
      </template>
    </v-data-table>
    <!--end::Table Results-->

    <!--begin::Row Pagination-->
    <div v-if="!ItemsSearching && ItemsList.length > 0" class="p-0 row">
      <div
        class="p-4 d-flex justify-content-between align-items-center flex-wrap w-100"
      >
        <div>
          {{
            $t("GENERIC.PAGINATION_SHOW", {
              from: ItemsFrom,
              to: ItemsTo,
              total: ItemsTotal
            })
          }}
        </div>

        <v-pagination
          v-model="ItemsCurrentPage"
          :length="ItemsPages"
          total-visible="10"
          @input="searchVehicleModels(true)"
          :previous-aria-label="$t('GENERIC.PREVIOUS')"
          :next-aria-label="$t('GENERIC.NEXT')"
        />

        <div class="d-flex align-items-baseline py-3">
          <v-select
            class="form-control form-control-sm mr-0 border-0 bg-light"
            style="max-width: 5em;"
            v-model="ItemsPerPage"
            single-line
            :items="ItemsPerPageOptions"
            @change="updateItemsPerPage"
          />
          <span>{{ $t("GENERIC.PAGINATION_MENU") }}</span>
        </div>
      </div>
    </div>
    <!--end::Row Pagination-->

    <!--begin::Row Zero-->
    <div v-if="!ItemsSearching && ItemsList.length < 1" class="search-info">
      <inline-svg class="mx-3" src="/media/icons/help-alt-5-success.svg" />
      {{ $t("GENERIC.NO_RESULTS") }}
    </div>
    <!--end::Row Zero-->
  </div>
  <!--end::VehicleModels index-->
</template>

<script>
import Swal from "sweetalert2";

import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME
} from "@/core/services/store/htmlclass.module.js";

import VehicleModelService from "@/core/services/api/v2/vehiclemodel.service";
import VehicleTypeService from "@/core/services/api/v2/vehicletype.service";

export default {
  name: "VehicleModelsIndex",
  data() {
    return {
      TableHeaders: [
        { text: this.$t("VEHICLE_MODELS.BRAND"), value: "Brand" },
        { text: this.$t("VEHICLE_MODELS.MODEL"), value: "Model" },
        { text: this.$t("VEHICLE_MODELS.VERSION"), value: "Version" },
        { text: this.$t("VEHICLE_MODELS.YEAR"), value: "YearFrom" },
        { text: this.$t("VEHICLE_MODELS.TYPE"), value: "VehicleTypeID" },
        { text: this.$t("GENERIC.ACTIONS"), width: 120, sortable: false }
      ],
      options: {},
      VehicleTypeOptions: [],
      VehicleTypeNames: [],
      SortBy: "Brand",
      SortDesc: false,
      ItemsList: [],
      ItemsSearching: true,
      DebounceSearchText: null,
      SearchText: "",
      ShowFilters: false,
      FilterByVehicleTypeID: 0,
      FilterByBrand: "",
      FilterByModel: "",
      FilterByVersion: "",
      ItemsFrom: 0,
      ItemsTo: 0,
      ItemsTotal: 0,
      ItemsPerPage: 5,
      ItemsPages: 1,
      ItemsCurrentPage: 1,
      ItemsPerPageOptions: [
        { text: 5, value: 5 },
        { text: 10, value: 10 },
        { text: 25, value: 25 },
        { text: 50, value: 50 }
      ]
    };
  },

  mounted() {
    // Show page loader
    this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");

    this.loadVehicleTypeOptions();
  },

  methods: {
    loadVehicleTypeOptions() {
      this.VehicleTypeOptions = [];
      this.VehicleTypeOptions.push({ VehicleTypeID: 0, Name: " " });

      this.VehicleTypeNames = [];
      this.VehicleTypeNames[0] = " ";

      VehicleTypeService.listAll().then(response => {
        response.forEach(vt => {
          this.VehicleTypeOptions.push(vt);
          this.VehicleTypeNames[vt.VehicleTypeID] =
            vt.Position + ": " + vt.Name;
        });

        this.loadCachedFilters();

        // Remove page loader
        this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");

        // Do first search
        this.searchVehicleModels(false);
      });
    },

    loadCachedFilters() {
      let anyFilterNotEmpty = false;

      Object.entries(VehicleModelService.getCachedFilters()).forEach(f => {
        this["FilterBy" + f[0]] = f[1];

        if (!anyFilterNotEmpty) {
          anyFilterNotEmpty = f[1].toString().length > 0;
        }
      });

      this.ShowFilters = anyFilterNotEmpty;
    },

    searchVehicleModels(keepCurrentPage) {
      this.ItemsSearching = true;
      this.ItemsList = [];

      // Compute pagination params
      if (!keepCurrentPage) {
        this.ItemsCurrentPage = 1;
      }

      const limit = this.ItemsPerPage;
      const offset =
        this.ItemsCurrentPage === 0 ? 0 : limit * (this.ItemsCurrentPage - 1);

      VehicleModelService.search(
        this.SearchText,
        this.FilterByBrand,
        this.FilterByModel,
        this.FilterByVersion,
        this.FilterByVehicleTypeID,
        this.SortBy,
        this.SortDesc,
        limit,
        offset
      ).then(rawResponse => {
        if (rawResponse === 404) {
          this.ItemsList = [];
          this.ItemsPages = 1;
        } else {
          this.ItemsList = rawResponse.response.VehicleModels;

          this.ItemsTotal = rawResponse.total;
          this.ItemsPages = Math.ceil(this.ItemsTotal / this.ItemsPerPage);
          this.ItemsFrom = (this.ItemsCurrentPage - 1) * this.ItemsPerPage + 1;
          this.ItemsTo = this.ItemsFrom + this.ItemsList.length - 1;
        }

        this.ItemsSearching = false;
      });
    },

    updateSortBy(event) {
      if (event.length > 0) {
        this.SortBy = event[0];
        this.SortDesc = false;
        this.ItemsCurrentPage = 1;
        this.searchVehicleModels(false);
      }
    },
    updateSortDesc(event) {
      this.SortDesc = event.length > 0 ? event[0] : this.SortDesc;
      this.ItemsCurrentPage = 1;
      this.searchVehicleModels(false);
    },
    updateItemsPerPage(newValue) {
      this.ItemsPerPage = newValue;
      this.ItemsCurrentPage = 1;
      this.searchVehicleModels(false);
    },

    changeItemsCurrentPage() {
      this.searchVehicleModels(true);
    },

    cleanFilters() {
      this.FilterByVehicleTypeID = 0;
      this.FilterByBrand = "";
      this.FilterByModel = "";
      this.FilterByVersion = "";
      this.ShowFilters = false;
      this.searchVehicleModels(false);
    },

    onSearchTextKeyup() {
      clearTimeout(this.DebounceSearchText);
      this.DebounceSearchText = setTimeout(() => {
        this.searchVehicleModels(false);
      }, 600);
    },

    getVehicleTypeName(vtID) {
      let name = this.VehicleTypeNames[vtID];
      if (!name) {
        name = " - ";
      }

      return name;
    },

    deleteVehicle(item) {
      if (!item.ImportedFromCar2DB) {
        Swal.fire({
          title:
            this.$i18n.t("GENERIC.DELETE_ALERT") +
            "\n" +
            item.Brand +
            " " +
            item.Model +
            " " +
            item.Version,
          text: this.$i18n.t("GENERIC.DELETE_ALERT2"),
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: this.$i18n.t("GENERIC.DELETE_YES"),
          cancelButtonText: this.$i18n.t("GENERIC.DELETE_NO"),
          customClass: {
            confirmButton: "btn btn-pill btn-danger button-form",
            cancelButton:
              "btn btn-pill btn-white button-form button-form-primary"
          },
          buttonsStyling: false
        }).then(result => {
          if (result.isConfirmed) {
            VehicleModelService.deleteProfile(item.VehicleModelID).then(
              statusCode => {
                if (statusCode >= 400) {
                  this.$bvToast.toast(this.$i18n.t("GENERIC.TRY_LATER"), {
                    title: this.$i18n.t("GENERIC.DELETE_ERROR"),
                    variant: "danger",
                    solid: true
                  });
                } else {
                  Swal.fire({
                    text: this.$i18n.t("GENERIC.DELETE_OK"),
                    icon: "success",
                    confirmButtonText: this.$i18n.t("GENERIC.CLOSE"),
                    customClass: {
                      confirmButton:
                        "btn btn-tertiary btn-lg font-weight-bolder"
                    },
                    buttonsStyling: false,
                    focusConfirm: false
                  });

                  this.searchVehicleModels(true);
                }
              }
            );
          }
        });
      }
    }
  }
};
</script>
